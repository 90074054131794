// Here you can add other styles
.form-select:disabled {
    /* color: var(--cui-form-select-disabled-color); */
    background-color: #f2f2f2 !important;
    color: #999999 !important;
}

.form-control:disabled {
    background-color: #f2f2f2 !important;
    color: #999999 !important;
    opacity: 1;
}

.sidebar-nav .nav-group.show {
    background-color: #f2f2f2 !important;
}

.form-check-input[type=checkbox] {
    border-color: #343a40 !important;
}