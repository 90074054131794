.status-table {
    height: calc(100vh - 200px) !important;
}

.fix-pos {
    position: sticky !important;
    top: 0;
    /* Ensures the background color of the sticky header */
    z-index: 1;
}

.w-h-75 {
    width: 85% !important;
    height: 85% !important;
}

.modal-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.modal-td,
.modal-th {
    border: 1px solid #343a40;
    text-align: left;
    padding: 8px;
}

.modal-tr:nth-child(even) {
    background-color: #f2f2f2;
}